import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './index.css';

export class Article extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    authorTitle: PropTypes.string.isRequired,
    authorCompany: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    videoUrl: PropTypes.string,
  };

  render() {
    const {
      title,
      date,
      author,
      authorTitle,
      authorCompany,
      content,
      videoUrl,
    } = this.props;

    return (
      <div className="component article">
        {videoUrl && (
          <div className="article-video">
            <iframe
              align="middle"
              allowFullScreen={true}
              frameBorder="0"
              scrolling="no"
              height="100%"
              width="100%"
              src={videoUrl}
              title={title}
            ></iframe>
          </div>
        )}

        <div className="article-container">
          <div className="article-information">
            <h1>{title}</h1>
            <div className="article-date">{date}</div>
            <div className="byline">
              <div className="byline-label">
                by <span className="byline-author">{author}</span>
              </div>
              <div className="byline-title">{authorTitle}</div>
              <div className="byline-company">{authorCompany}</div>
            </div>
          </div>

          <div
            className="article-contents"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    );
  }
}

export default Article;
