import React from 'react';

import ArticlePage from '../../components/pages/resources/ArticlePage';

import './index.css';

export default (props) => {
  const { pageContext } = props;

  const { resource } = pageContext;

  return (
    <div className="template article-page">
      <ArticlePage article={resource} />
    </div>
  );
};
